.bottomMenu__bar {
  background-color: $primary-color;
  position: relative;
  //height: 20px;
  height: 22px;
  cursor: ns-resize;
  @include media-breakpoint-up(sm){
    height: 26px;
  }
}

.bottomMenu__line1,
.bottomMenu__line2 {
  background-color: rgba(white, 0.5);
  height: 3px;
  width: 40px;
  position: absolute;
  transform: translateX(-50%);
  position: absolute;
  left: 50%;
}

.bottomMenu__line1 {
  top: 5px;
}

.bottomMenu__line2 {
  top: 12px;
}

.bottomMenu__text {
  transform: translateX(-50%);
  position: absolute;
  left: 50%;
  bottom: 0px;
  color: rgba(white, 0.8);
  font-size: 0.7rem;
  font-weight: $weight-bold;
  line-height: 1;
  svg{
    font-size: 1rem;
    position: absolute;
    top: 37%;
    transform: translateY(-50%);
    right: -1.2rem;
  }
}

.bottomMenuPadding {
  width: 100%;
  height: 10px;
  background-color: $color-orange;
  //background-color: red;
  z-index: 101;
  position: fixed;
  bottom: 0;

  //iPhone X
  @media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
    height: 20px;
  }
}